import "./style.scss";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { playSound } from "../../../audio-notification";
import { CcpManager } from "../../../connect/ccp-manager";
import { TrackedEvent, trackEvent } from "../../../snowplow";
import { CcpContainer } from "../../CcpContainer";
import { enableAudioNotifications } from "../../config";
import {
  useCloseCcpWindow,
  useInitSelectedInstanceCcp,
  useOpenCcpWindow,
} from "../../hooks/ccp-manager";
import { useMounted } from "../../hooks/common";
import { useInstanceSelector } from "../../hooks/instance-selector";
import { LaunchMenu } from "../../LaunchMenu";
import { Translation } from "../../Translation";

export interface MainContainer {
  onCloseClick?: () => void;
  connectInstanceCcpUrl?: string;
}

/**
 * The root component that has access to the redux store.
 * Controls the Ccp and Launch menu
 */
export const MainContainer: React.FC<MainContainer> = ({
  onCloseClick,
  connectInstanceCcpUrl,
}) => {
  // Ref to the ccpContainer instance methods
  const ccpContainerRef = useRef<HTMLDivElement>(null);

  const { setSelectedInstance, instances } = useInstanceSelector();

  // Local State
  const [isLaunched, setIsLaunched] = useState<boolean>(false);

  // CCP callbacks
  const initSelectedInstanceCcp = useInitSelectedInstanceCcp();

  const initCcp = useCallback(async () => {
    if (ccpContainerRef.current) {
      await initSelectedInstanceCcp(ccpContainerRef.current);
    }
  }, [ccpContainerRef, initSelectedInstanceCcp]);

  // Custom Hooks
  const openCcpWindow = useOpenCcpWindow();
  const closeCcpWindow = useCloseCcpWindow();
  const mounted = useMounted();

  // Handlers
  // for popping out agent.html and back in
  const handlePopClick = (): void => {
    if (mounted.current) {
      // if connectInstanceCcpUrl is present it means it is already agent.html
      connectInstanceCcpUrl ? closeCcpWindow() : openCcpWindow();
    }
  };

  const handleLaunchClick = async (): Promise<void> => {
    setIsLaunched(true);
    await initCcp();

    CcpManager.instance.onChatConnected(({ chatSession }) => {
      chatSession.onMessage((event) => {
        if (enableAudioNotifications()) playSound(event);
        const { contactId } = event.chatDetails;
        const messageId = event.data.Id;
        const timestamp = event.data.AbsoluteTime;
        trackEvent({
          elementId: TrackedEvent.MessageReceived,
          elementTarget: contactId,
          elementContent: messageId,
          targetUrl: timestamp,
        });
      });
    });

    Translation.instance.initTranslation();
  };

  useEffect(() => {
    if (connectInstanceCcpUrl) {
      const selectedInstance = instances.find(
        (instance) => instance.ccpUrl === connectInstanceCcpUrl
      );
      setSelectedInstance(selectedInstance);
      void handleLaunchClick();
    }
  }, []);

  const handleCloseClick = (): void => {
    onCloseClick && onCloseClick();
  };

  return (
    <Fragment>
      {!connectInstanceCcpUrl && (
        <LaunchMenu
          onLaunchClick={handleLaunchClick}
          onCloseClick={handleCloseClick}
        />
      )}
      <CcpContainer
        style={{ visibility: isLaunched ? "visible" : "hidden" }}
        ref={ccpContainerRef}
        onPopClick={handlePopClick}
        onCloseClick={handleCloseClick}
      />
    </Fragment>
  );
};
